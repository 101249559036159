import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import NavLink from '../navLink/NavLink'
import SearchBox from '../searchBox/SearchBox'
import SocialMediaIcons from '../../sharedComponents/socialMediaIcons/SocialMediaIcons'
import PropTypes from 'prop-types'
import styles from './slideOutMenu.module.css'

const SlideOutMenu = ({ isOpen }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            navigation {
              display
              path
            }
          }
        }
      }
    `
  )

  const links = data.site.siteMetadata.navigation.map((link, index) => {
    return <NavLink path={link.path} display={link.display} key={index} />
  })

  return (
    <div
      className={`${styles.slideOutMenu} ${
        isOpen ? styles.slideOutMenuOpen : ''
      }`}
    >
      <div className={styles.menuItemsContainer}>
        <div className={styles.searchBoxContainer}>
          <SearchBox width="80%" height="40px" />
        </div>
        <div className={styles.linksContainer}>
          <ul className={styles.navLinks}>{links}</ul>
        </div>
        <div className={styles.socialMediaContainer}>
          <SocialMediaIcons space="30px" size="25px" />
        </div>
      </div>
    </div>
  )
}

SlideOutMenu.propTypes = {
  isOpen: PropTypes.bool,
}

export default SlideOutMenu
