import React, { useState } from 'react'
import { BlogData } from '../../contexts/blogData/blogData'
import { CampingData } from '../../contexts/campingData/campingData'
import HamburgerButton from './hamburgerButton/HamburgerButton'
import { LodgingData } from '../../contexts/lodgingData/lodgingData'
import MediaQuery from '../sharedComponents/MediaQuery/MediaQuery'
import NavLink from './navLink/NavLink'
import { useStaticQuery, graphql } from 'gatsby'
import SearchBox from './searchBox/SearchBox'
import SiteLogo from './siteLogo/SiteLogo'
import SlideOutMenu from './slideOutMenu/SlideOutMenu'
import SocialMediaIcons from '../sharedComponents/socialMediaIcons/SocialMediaIcons'
import styles from './navigation.module.css'

const queries = {
  mobile: '(max-width: 1045px)',
  desktop: '(min-width: 1046px)',
}

const Navigation = () => {
  const [isMobileMenuOpen, toggleMobileMenuState] = useState(false)

  function toggleMenuSlider() {
    toggleMobileMenuState(!isMobileMenuOpen)
  }

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            navigation {
              display
              path
            }
          }
        }
      }
    `
  )

  const links = data.site.siteMetadata.navigation.map((link, index) => {
    return <NavLink path={link.path} display={link.display} key={index} />
  })

  const matchPoints = MediaQuery(queries)

  return (
    <BlogData>
      <CampingData>
        <LodgingData>
          <div className={styles.navigationWrapper}>
            <nav className={styles.navigation}>
              <SiteLogo />
              {matchPoints && matchPoints.mobile ? (
                <HamburgerButton
                  toggleMenuOpenState={toggleMenuSlider}
                  isOpen={isMobileMenuOpen}
                />
              ) : null}
              <SlideOutMenu isOpen={isMobileMenuOpen} />

              {matchPoints && matchPoints.desktop ? (
                <ul className={styles.navLinks}>{links}</ul>
              ) : null}
            </nav>
            {matchPoints && matchPoints.desktop ? (
              <div className={styles.searchBarWrapper}>
                <div className={styles.searchBar}>
                  <div className={styles.searchBarIcons}>
                    <SocialMediaIcons space="50px" size="25px" />
                  </div>
                  <div className={styles.searchBarSearchBox}>
                    <SearchBox />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </LodgingData>
      </CampingData>
    </BlogData>
  )
}

export default Navigation
