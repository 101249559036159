import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import navStyles from './navLink.module.css'

const NavLink = ({ display, path }) => {
  return (
    <li className={navStyles.navLinkContainer}>
      <Link className={navStyles.navLink} to={path}>
        {display}
      </Link>
    </li>
  )
}

NavLink.propTypes = {
  display: PropTypes.string,
  path: PropTypes.string,
}

export default NavLink
