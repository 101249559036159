import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import SocialMediaIcon from './socialMediaIcon/SocialMediaIcon'
import styles from './socialMediaIcons.module.css'

const SocialMediaIcons = ({ space, size }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialMediaLinks {
              icon
              url
            }
          }
        }
      }
    `
  )

  const lastIcon = data.site.siteMetadata.socialMediaLinks.length - 1

  const icons = data.site.siteMetadata.socialMediaLinks.map((link, index) => {
    if (index === lastIcon) {
      space = '0'
    }

    return (
      <SocialMediaIcon
        icon={link.icon}
        url={link.url}
        key={index}
        space={space}
        size={size}
      />
    )
  })

  return <div className={styles.socialMediaContainer}>{icons}</div>
}

SocialMediaIcons.propTypes = {
  space: PropTypes.string,
  size: PropTypes.string,
}

export default SocialMediaIcons
