import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { buildSearchText } from '../helpers'

const computeDifficulty = color => {
  let difficulty
  switch (color) {
    case 'green':
      difficulty = 'Easy'
      break
    case 'greenBlue':
      difficulty = 'Easy/Intermediate'
      break
    case 'blue':
      difficulty = 'Intermediate'
      break
    case 'blueBlack':
      difficulty = 'Intermediate/Difficult'
      break
    case 'black':
      difficulty = 'Difficult'
      break
    case 'dblack':
      difficulty = 'Extremely Difficult'
      break
    default:
    // code block
  }

  return difficulty
}

export const normalizeBlogData = data => {
  const blogData = data.allMarkdownRemark.edges
  const locationsData = data.allGooglePlacesData.edges[0].node.placesData
  return blogData.map(data => {
    const locationData = locationsData.find(
      location => location.video_id === data.node.frontmatter.videoId
    )
    const searchableText = buildSearchText(data.node.frontmatter)

    const trailDifficulty = locationData.trail
      ? computeDifficulty(locationData.trail.difficulty)
      : null

    const placeHours = locationData.opening_hours
      ? locationData.opening_hours.weekday_text
      : null
    return {
      type: data.node.fields.type,
      ...data.node.frontmatter,
      ...locationData,
      phone_number:
        data.node.frontmatter.phone_number || locationData.phone_number,
      website: data.node.frontmatter.website || locationData.website,
      hours: data.node.frontmatter.hours || placeHours,
      trail: locationData.trail
        ? {
            ...locationData.trail,
            difficulty: trailDifficulty,
          }
        : null,
      html: data.node.html,
      searchableText,
    }
  })
}
const BlogContext = React.createContext()
const BlogProvider = BlogContext.Provider

export const BlogData = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: {
            fields: { type: { nin: ["information", "camping", "lodging"] } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                seo_title
                seo_description
                slug
                alt
                title
                author
                date
                displayName
                thumbnail
                summary
                info_summary
                parent_facility
                phone_number
                website
                park_map
                hours
                hiking_map_coords
                trail_features
                trail_type
                weather_station_coords
                weather_station_name
                prices {
                  display
                  value
                }
                layout
                videoId
                categories
                camping
                placeId
              }
              html
              fields {
                slug
                type
              }
            }
          }
        }
        allGooglePlacesData {
          edges {
            node {
              placesData {
                video_id
                address
                facility_id
                google_map_link
                opening_hours {
                  weekday_text
                }
                latitude
                longitude
                name
                phone_number
                website
                place_id
                trail {
                  id
                  name
                  summary
                  difficulty
                  length
                  ascent
                  descent
                  high
                  low
                  longitude
                  latitude
                  conditionStatus
                  conditionDetails
                  conditionDate
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <BlogProvider value={normalizeBlogData(cloneDeep(data))}>
      {children}
    </BlogProvider>
  )
}

BlogData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default BlogContext
