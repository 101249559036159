import React from 'react'
import { Link } from 'gatsby'
import SocialMediaIcons from '../sharedComponents/socialMediaIcons/SocialMediaIcons'
import styles from './footer.module.css'

const Footer = () => (
  <div className={styles.footerContainer}>
    <footer className={styles.footer}>
      <div className={styles.footerInfo}>
        <div className={styles.footerPrivacyTerms}>
          <Link className={styles.footerLink} to="/privacy/">
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link className={styles.footerLink} to="/terms/">
            Terms of Use
          </Link>
        </div>
        © {new Date().getFullYear()} Cactus Atlas
      </div>
      <div className={styles.footerContact}>
        <div className={styles.footerContactLink}>
          <Link className={styles.footerLink} to="/contact/">
            Contact Us
          </Link>
        </div>
        <div className={styles.socialMediaContainer}>
          <SocialMediaIcons space="30px" size="25px" />
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
