import React from 'react'
import styles from './hamburger.module.css'
import PropTypes from 'prop-types'

const HamburgerButton = ({ isOpen, toggleMenuOpenState }) => (
  <button
    className={styles.hamburgerButton}
    onClick={() => {
      toggleMenuOpenState()
    }}
  >
    <div
      className={`${isOpen ? styles.hamburgerOpen : ''} ${styles.hamburger}`}
    >
      <div className={styles.hamburgerLine}></div>
    </div>
  </button>
)

HamburgerButton.propTypes = {
  isOpen: PropTypes.bool,
  toggleMenuOpenState: PropTypes.func,
}

export default HamburgerButton
