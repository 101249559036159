import React from 'react'
import { Link } from 'gatsby'
import styles from './siteLogo.module.css'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const SiteLogo = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      image: file(relativePath: { eq: "cactus-atlas-red-rock.png" }) {
        id
        childImageSharp {
          fixed(height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className={styles.logoContainer}>
      <div className={styles.logo}>
        <Link to="/">
          <Img
            fixed={data.image.childImageSharp.fixed}
            alt="Cactus Atlas Logo"
          />
        </Link>
      </div>
      <div>
        <Link to="/" className={styles.name}>
          <p>Cactus</p>
          <p>Atlas</p>
        </Link>
      </div>
    </div>
  )
}

export default SiteLogo
