import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import CampingContext from '../../../contexts/campingData/campingData'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import { navigate } from 'gatsby'
import Select from 'react-dropdown-select'
import styles from './searchBox.module.css'

import { orderBy } from 'lodash'

const SearchBox = () => {
  const options = orderBy(
    [
      ...useContext(BlogContext),
      ...useContext(CampingContext),
      ...useContext(LodgingContext),
    ],
    ['displayName']
  ).map(data => {
    return {
      searchText: data.searchableText,
      name: data.displayName,
      url: `/${data.type}/${data.slug}`,
    }
  })
  const onOptionSelect = selectedValue => {
    if (selectedValue.length) {
      navigate(selectedValue[0].url)
    }
  }

  return (
    <div className={styles.searchContainer}>
      <Select
        className={styles.searchBox}
        placeholder="Search"
        options={options}
        values={[]}
        labelField="name"
        valueField="searchText"
        onChange={value => onOptionSelect(value)}
        noDataRenderer={() => (
          <div className={styles.noMatch}>Sorry, no matches found...</div>
        )}
      />
    </div>
  )
}

export default SearchBox
