import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { buildSearchText } from '../helpers'

export const normalizeLodgingData = data => {
  const locationsData = data.allGooglePlacesData.edges[0].node.placesData

  return data.allMarkdownRemark.edges.map(lodgingData => {
    const searchableText = buildSearchText(lodgingData.node.frontmatter)
    const locationData = locationsData.find(
      location => location.place_id === lodgingData.node.frontmatter.placeId
    )
    return {
      type: lodgingData.node.fields.type,
      ...lodgingData.node.frontmatter,
      ...locationData,
      phone_number:
        lodgingData.node.frontmatter.phone_number || locationData.phone_number,
      website: lodgingData.node.frontmatter.website || locationData.website,
      searchableText,
    }
  })
}

const LodgingContext = React.createContext()
const LodgingProvider = LodgingContext.Provider

export const LodgingData = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { fields: { type: { eq: "lodging" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                seo_title
                seo_description
                slug
                alt
                title
                author
                date
                displayName
                thumbnail
                summary
                layout
                placeId
                videoId
                categories
                state
                rates
                reservation_page
                amenities
                overview
                nearby
                weather_station_coords
                weather_station_name
                parent_facility
                phone_number
                website
                park_map
              }
              fields {
                type
              }
            }
          }
        }
        allGooglePlacesData {
          edges {
            node {
              placesData {
                address
                directions
                google_map_link
                latitude
                longitude
                name
                phone_number
                place_id
                website
              }
            }
          }
        }
      }
    `
  )
  return (
    <LodgingProvider value={normalizeLodgingData(data)}>
      {children}
    </LodgingProvider>
  )
}

LodgingData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default LodgingContext
