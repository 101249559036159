import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { buildSearchText } from '../helpers'

export const normalizeRates = rates => {
  if (rates && rates.max) {
    return `$${rates.min} - $${rates.max} per night`
  }
}

export const computeToiletType = amenities => {
  if (amenities) {
    const toiletType = amenities.find(
      amenity => amenity === 'Flush Toilets' || amenity === 'Vault Toilets'
    )
    return toiletType ? toiletType : null
  }
}

export const computeIsCampfireAllowed = amenities => {
  if (amenities) {
    const isCampfireAllowed = amenities.find(
      amenity => amenity === 'Campfire Allowed'
    )
    return !!isCampfireAllowed
  }
}

export const computeIsDrinkableWater = amenities => {
  if (amenities) {
    const isDrinkableWater = amenities.find(
      amenity => amenity === 'Drinkable Water'
    )
    return !!isDrinkableWater
  }
}

export const computeMaxLength = length => {
  if (length) {
    return `Max Length: ${length} ft`
  }
}

export const computePetsAllowed = amenities => {
  if (amenities) {
    const isPetsAllowed = amenities.find(amenity => amenity === 'Pets Allowed')
    return !!isPetsAllowed
  }
}

export const computeOverview = data =>
  data.descriptions ? stripMarkupFromString(data.descriptions.overview) : null

const stripMarkupFromString = string => string.replace(/(<([^>]+)>)/gi, '')

export const normalizeCampingData = data => {
  const locationsData = data.allGooglePlacesData.edges[0].node.placesData

  return data.allMarkdownRemark.edges.map(campData => {
    const searchableText = buildSearchText(campData.node.frontmatter)
    const locationData = locationsData.find(
      location => location.place_id === campData.node.frontmatter.placeId
    )
    return {
      type: campData.node.fields.type,
      ...campData.node.frontmatter,
      ...locationData,
      isAccessible: campData.node.frontmatter.accessible_sites || false,
      amenities: campData.node.frontmatter.amenities || null,
      overview: campData.node.frontmatter.overview || null,
      max_vehicle_length: campData.node.frontmatter.max_vehicle_length || null,
      number_of_campsites:
        campData.node.frontmatter.number_of_campsites || null,
      rates: campData.node.frontmatter.rates || null,
      reservation_page: campData.node.frontmatter.reservation_page || null,
      toilets:
        computeToiletType(campData.node.frontmatter.amenities) || 'No Toilets',
      isCampfireAllowed:
        computeIsCampfireAllowed(campData.node.frontmatter.amenities) || false,
      isPetsAllowed:
        computePetsAllowed(campData.node.frontmatter.amenities) || false,
      isDrinkableWater:
        computeIsDrinkableWater(campData.node.frontmatter.amenities) || false,
      phone_number: campData.node.frontmatter.phone_number || null,
      website: campData.node.frontmatter.website || null,
      searchableText,
    }
  })
}

const CampingContext = React.createContext()
const CampingProvider = CampingContext.Provider

export const CampingData = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { fields: { type: { eq: "camping" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                seo_title
                seo_description
                slug
                alt
                title
                author
                date
                displayName
                thumbnail
                summary
                layout
                placeId
                videoId
                categories
                state
                accessible_sites
                number_of_campsites
                max_vehicle_length
                rates
                reservation_page
                amenities
                overview
                nearby
                weather_station_coords
                weather_station_name
                parent_facility
                phone_number
                website
                park_map
              }
              fields {
                type
              }
            }
          }
        }
        allGooglePlacesData {
          edges {
            node {
              placesData {
                accessible_site_data {
                  accessible_sites {
                    accessible_site_name
                    accessible_site_reservation_link
                  }
                  number_of_accessible_sites
                }
                address
                campsite_types
                descriptions {
                  facilities
                  natural_features
                  nearby_attractions
                  overview
                  recreation
                }
                directions
                facility_id
                google_map_link
                latitude
                longitude
                name
                notices {
                  active
                  notice_text
                  notice_type
                }
                place_id
                total_open_campsites
              }
            }
          }
        }
      }
    `
  )
  return (
    <CampingProvider value={normalizeCampingData(data)}>
      {children}
    </CampingProvider>
  )
}

CampingData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default CampingContext
