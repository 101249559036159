import React from 'react'
import PropTypes from 'prop-types'

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
} from 'react-icons/fa'

import { IconContext } from 'react-icons'

import styles from './socialMediaIcon.module.css'

export const getFontAwesomeComponent = icon => {
  let fa
  switch (icon) {
    case 'facebook':
      fa = <FaFacebookF />
      break
    case 'twitter':
      fa = <FaTwitter />
      break
    case 'instagram':
      fa = <FaInstagram />
      break
    case 'youtube':
      fa = <FaYoutube />
      break
    case 'pinterest':
      fa = <FaPinterest />
      break
    default:
      fa = null
  }
  return fa
}

const SocialMediaIcon = ({ icon, url, space, size }) => {
  const faComponent = getFontAwesomeComponent(icon)
  if (faComponent && url)
    return (
      <div className={styles.socialMediaIcon} style={{ marginRight: space }}>
        <a className={styles.searchbarSocMedIcon} href={url}>
          <IconContext.Provider value={{ size: size }}>
            {faComponent}
          </IconContext.Provider>
        </a>
      </div>
    )
  return null
}

SocialMediaIcon.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string,
  space: PropTypes.string,
  size: PropTypes.string,
}

export default SocialMediaIcon
